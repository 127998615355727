// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    s3:{
        accessKeyId:"AKIA3GV2KW2THNUZYXQS",
        secretAccessKey:"XaHZhFag9kgEN3LrW0OPjQaVUd5O6TnmSHw3BLWX",
        region:"eu-central-1",
        fullBucket:"app-milkspin-sample-original-images-prod",
        thumbnailBucket:"app-milkspin-sample-thumbnails-prod"
    },
    backendURL:"http://milkspin.promity.pl",
    backendPort:"80/backend",
    token:"51e78729-50ba-4fad-83ca-a075f9de6969"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
